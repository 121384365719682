import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  netTrial: [],
  tab: 'gst',
  grossTrial: [],
  extendedTrial: [],
  printDate: null,
  lossSales: [],
  lossPurchase: [],
  purchaseData: [],
  saleData: [],
  tradingProfitLoss: [],
  liabilities: [],
  assets: [],
  expanadAll: false,
  expanadAll1: false,
  balanceSheet: [],
  taxReport: [],
  saleCustomer: [],
  saleItem: [],
  saleLocation: [],
  taxReportReturnPeriod: null,
  customerBalances: [],
  aging: [],
  agingDetails: [],
  agingDetailsExcel:[],
  invoiceDetails:[],
  invoiceDetailsExcel:[],
};

const slice = createSlice({
  name: 'gst',
  initialState,
  reducers: {
    setExpanadAll1(state, action) {
      state.expanadAll1 = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setExpanadAll(state, action) {
      state.expanadAll = action.payload;
    },
    setPrintDate(state, action) {
      state.printDate = action.payload;
    },
    setNetTrial(state, action) {
      state.netTrial = action.payload;
    },
    setGrossTrial(state, action) {
      state.grossTrial = action.payload;
    },
    setExtendedTrial(state, action) {
      state.extendedTrial = action.payload;
    },
    setlossSales(state, action) {
      state.lossSales = action.payload;
    },
    setlossPurchase(state, action) {
      state.lossPurchase = action.payload;
    },
    setPurchaseData(state, action) {
      state.purchaseData = action.payload;
    },
    setSaleData(state, action) {
      state.saleData = action.payload;
    },
    setTradingProfitLoss(state, action) {
      state.tradingProfitLoss = action.payload;
    },
    setLiabilities(state, action) {
      state.liabilities = action.payload;
    },
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setBalanceSheet(state, action) {
      state.balanceSheet = action.payload;
    },
    setTaxReport(state, action) {
      state.taxReport = action.payload;
    },
    setSalecustomer(state, action) {
      state.saleCustomer = action.payload;
    },
    setSaleItem(state, action) {
      state.saleItem = action.payload;
    },
    setSaleLocation(state, action) {
      state.saleLocation = action.payload;
    },
    setTaxReportReturnPeriod(state, action) {
      state.taxReportReturnPeriod = action.payload;
    },
    setCustomerBalances(state, action) {
      state.customerBalances = action.payload;
    },
    setAging(state, action) {
      state.aging = action.payload;
    },
    setAgingDetails(state, action) {
      state.agingDetails = action.payload;
    },
    setAgingDetailsExcel(state, action) {
      state.agingDetailsExcel = action.payload;
    },
    setInvoiceDetails(state, action) {
      state.invoiceDetails = action.payload;
    },
    setInvoiceDetailsExcel(state, action) {
      state.invoiceDetailsExcel = action.payload;
    },
  
  },
});

export default slice.reducer;

export const {
  setTaxReportReturnPeriod,
  setExpanadAll1,
  setTab,
  setNetTrial,
  setGrossTrial,
  setExtendedTrial,
  setPrintDate,
  setlossSales,
  setlossPurchase,
  setPurchaseData,
  setSaleData,
  setTradingProfitLoss,
  setLiabilities,
  setAssets,
  setExpanadAll,
  setBalanceSheet,
  setTaxReport,
  setSalecustomer,
  setSaleItem,
  setSaleLocation,
  setCustomerBalances,
  setAging,
  setAgingDetails,
  setAgingDetailsExcel,
  setInvoiceDetails,
  setInvoiceDetailsExcel,
} = slice.actions;

export const getAgingSummary = (ed) => async (dispatch) => {
  try {
    await axios.get(`/aging_summary?end=${ed}`).then((response) => dispatch(setAging(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCustomerBalances = (ed) => async (dispatch) => {
  try {
    await axios.get(`/Customer_Balances?end=${ed}`).then((response) => dispatch(setCustomerBalances(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSalesByItems = (ed) => async (dispatch) => {
  try {
    await axios.get(`/SaleByItems?end=${ed}`).then((response) => dispatch(setSaleItem(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSaleByLocation = (ed) => async (dispatch) => {
  try {
    await axios.get(`/SaleByLocation?end=${ed}`).then((response) => dispatch(setSaleLocation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getNetTrial = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/net_trial_balance?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setNetTrial(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGrossTrial = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/gross_trial_balance?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGrossTrial(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExtendendTrial = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/extended_trial_balance?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setExtendedTrial(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLossSales = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/profit_loss_sale?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setlossSales(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLossPurchase = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/profit_loss_purcase?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setlossPurchase(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPurchaseData = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase_data?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setPurchaseData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSaleData = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sale_data?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setSaleData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTradingProfitLoss = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/trading_profit_loss?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setTradingProfitLoss(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLiabilities = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/get_liabilities?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setLiabilities(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAssets = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/get_assets?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setAssets(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBalanceSheet = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/balanceSheet?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setBalanceSheet(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTaxReport = (filterStartDate) => async (dispatch) => {
  try {
    await axios
      .get(`/dashboard/taxReport?ret_period=${filterStartDate}`)
      .then((response) => dispatch(setTaxReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getsaleCustomer = (date) => async (dispatch) => {
  try {
    await axios.get(`/SaleByCustomer?end=${date}`).then((response) => dispatch(setSalecustomer(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAgingDetails = (ed, p, r, s) =>  async (dispatch) => {
  try{
    await axios.get(`/ARAgingDetails?end=${ed}&pagination=${p}&records=${r}&search=${s}`).then((response) => dispatch(setAgingDetails(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
}

export const getAgingDetailsExcel = (ed,) =>  async (dispatch) => {
  try{
    await axios.get(`/ARAgingDetailsExcel?end=${ed}`).then((response) => dispatch(setAgingDetailsExcel(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
}

export const getInvoiceDetails = (ed, p, r, s) =>  async (dispatch) => {
  try{
    await axios.get(`/InvoiceDetailsReport?end=${ed}&pagination=${p}&records=${r}&search=${s}`).then((response) => dispatch(setInvoiceDetails(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
}

export const getInvoiceDetailsExcel = (ed,) =>  async (dispatch) => {
  try{
    await axios.get(`/InvoiceDetailsReportExcel?end=${ed}`).then((response) => dispatch(setInvoiceDetailsExcel(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
}