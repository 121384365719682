import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  gstr1ViewInvoice: [],
  filterGstr1ViewInvoice: [],
  gstr1PrepareData: null,
  hsnSummary: [],
  gst2AData: [],
  tab: 'Input as per GST2A',
  notInbook: [],
  notIn2a: [],
  asPerbook: [],
  gst2BData: [],
  gst2aSummary: null,
  gst2bSummary: null,
  asperbooksSummary: null,
  isLoading: true,
};

const slice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    setGstr1ViewInvoice(state, action) {
      state.gstr1ViewInvoice = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setFilterGstr1ViewInvoice(state, action) {
      state.filterGstr1ViewInvoice = action.payload;
    },
    setGstr1PrepareData(state, action) {
      state.gstr1PrepareData = action.payload;
    },
    setHsnSummary(state, action) {
      state.hsnSummary = action.payload;
    },
    setGst2AData(state, action) {
      state.gst2AData = action.payload;
    },
    setGst2BData(state, action) {
      state.gst2BData = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setNotinbook(state, action) {
      state.notInbook = action.payload;
    },
    setNotin2a(state, action) {
      state.notIn2a = action.payload;
    },
    setAsperbook(state, action) {
      state.asPerbook = action.payload;
    },
    setGst2aSummary(state, action) {
      state.gst2aSummary = action.payload;
    },
    setGst2bSummary(state, action) {
      state.gst2bSummary = action.payload;
    },
    setAsperbooksSummary(state, action) {
      state.asperbooksSummary = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setGstr1ViewInvoice,
  setFilterGstr1ViewInvoice,
  setGstr1PrepareData,
  setHsnSummary,
  setGst2AData,
  setTab,
  setNotinbook,
  setNotin2a,
  setAsperbook,
  setGst2BData,
  setGst2aSummary,
  setGst2bSummary,
  setAsperbooksSummary,
  setIsLoading,
} = slice.actions;

export const getGst2aSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/get2A?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGst2aSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGst2bSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/get2B?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGst2bSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAsperbooksSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/AsPerBooks?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setAsperbooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAsperbook = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/dataAsPerBooks?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setAsperbook(response.data)));
      dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getNotIn2a = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`compare?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setNotin2a(response.data.notIn2A))); 
      dispatch(setIsLoading(false));
   
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getNotInbooks = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios.get(`compare?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`).then((response) => dispatch(setNotinbook(response.data.notInBooks)));
    dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGstr1ViewInvoice = (date) => async (dispatch) => {
  try {
    axios.get(`/gstr1ViewInvoice?ret_period=${date}`).then((response) => dispatch(setGstr1ViewInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFilterGstr1ViewInvoice = (date) => async (dispatch) => {
  try {
    axios
      .get(`/filterGstr1ViewInvoice?ret_period=${date}`)
      .then((response) => dispatch(setFilterGstr1ViewInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1PrepareData = (date) => async (dispatch) => {
  try {
    axios.get(`/gstr1PrepareData?ret_period=${date}`).then((response) => dispatch(setGstr1PrepareData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getHsnSummary = (date) => async (dispatch) => {
  try {
    axios.get(`/hsnSummary?ret_period=${date}`).then((response) => dispatch(setHsnSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGst2A = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/get2AData?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setGst2AData(response.data)));
      dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGst2B = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/get2BData?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setGst2BData(response.data)));
      dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};
